<template>
  <QuestionHeader></QuestionHeader>
  <div class="question_container">
    <div class="question_answers">
      <how-work-questions></how-work-questions>
      <navigation></navigation>
      <QuestionSteps></QuestionSteps>
    </div>
    <div class="question_info">
      <question-info :description="questionDescript" ></question-info>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import HowWorkQuestions from "@/components/questions/HowWorkQuestions";
import QuestionInfo from "@/components/QuestionInfo";
import QuestionSteps from "@/components/QuestionSteps";
import QuestionHeader from "@/components/QuestionHeader";

export default {
  name: "HowWork",
  components: {QuestionHeader, QuestionSteps, HowWorkQuestions, Navigation, QuestionInfo},
  setup() {
    const questionDescript = "Forstå, hvilke værktøjer din Buyer Persona elsker at bruge, kan hjælpe dig med at " +
        "identificere features i dit eget produkt eller din tjeneste, som kan give værdi. Det giver også indsigt i " +
        "deres operativsystem - både internt og eksternt - hvilket er nyttigt, når du positionere din marketing " +
        "budskaber og vælger, hvilke fordele der skal fremhæves."

    return{
      questionDescript
    }
  }
}
</script>

<style scoped>

</style>