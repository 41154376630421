<template>
  <div>
    <h1>Hvordan de arbejder</h1>
    <h2>Trin 6/7</h2>
  </div>
  <div class="answer_field_list">
    <div class="answer_field">
      <h3>Hvilke værktøjer bruger de eller har brug for til at udføre deres job?</h3>
      <div class="cb_list">
        <div v-for="tool of listTools" :key="tool.id" class="p-field-checkbox">
          <Checkbox :id="tool.id" name="tools" :value="tool.val" v-model="personaTools" />
          <label :for="tool.id">{{ tool.val }}</label>
        </div>
      </div>
      <div>
        <span class="p-float-label">
          <InputText id="additionalTool" type="text" @keydown="addAdditionalTool" v-model="additionalTool"/>
          <label for="additionalTool">Hvis ingen af ovenstående muligheder passer, kan du tilføje en her og trykke på enter.</label>
        </span>
      </div>
    </div>
    <div class="answer_field">
      <h3>Hvordan foretrækker de at kommunikere med leverandører / andre virksomheder?</h3>
      <div class="communication_list">
        <div class="">
          <SelectButton v-model="personaCommunicate" :options="communicateOptions" optionLabel="name" multiple>
            <template #option="slotProps">
              <div>
                <i :class="slotProps.option.icon"></i>
                <label>{{slotProps.option.name}}</label>
              </div>
            </template>
          </SelectButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import store from "@/store/store";
export default {
  name: "HowWorkQuestions",
  components: {},
  setup() {
    const listTools = computed( () => { return store.state.listTools})

    const personaTools = computed({
      get: () => store.state.personaTools,
      set: val => {
        store.dispatch(('setPersonaTools'), {tools: val})
      }
    })

    let additionalTool = ref("")

    const communicateOptions = computed( () => store.state.listCommunicate )

    let personaCommunicate = computed({
      get: () => store.state.personaCommunicate,
      set: val => {
        store.dispatch(('setPersonaCommunicate'), {communicate: val})
      }
    })

    function addAdditionalTool(e) {
      if(e.keyCode === 13) {
        const toolId = additionalTool.value.replace(' ', '-')
        const tool = {id: toolId, val: additionalTool.value}

        let toolFound = listTools.value.find( tool => tool.id === toolId )
        if(!toolFound) {
          store.dispatch(('updateListTools'), {tool: tool})
          store.dispatch(('updatePersonaTools'), {tool: tool.val})
          additionalTool.value = "";
        }
      }
    }

    return{
      listTools,
      personaTools,
      additionalTool,
      addAdditionalTool,
      personaCommunicate,
      communicateOptions
    }
  }
}
</script>

<style scoped>
.answer_field_list{
  margin-bottom: 220px;
}

::v-deep(.p-buttonset .p-button:first-of-type){
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

::v-deep(.p-selectbutton.p-buttonset .p-button){
  border-radius: 10px;
  margin-right: 20px;
  margin-top: 10px;
}

::v-deep(.p-buttonset .p-button:not(:last-child)){
  border-right: 1px solid #ff2a55;
}

</style>